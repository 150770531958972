import React from 'react';
import Icon1 from '../../assets/img/landingIcons/icon1.svg';
import Icon2 from '../../assets/img/landingIcons/icon2.svg';
import Icon3 from '../../assets/img/landingIcons/icon3.svg';
import Icon4 from '../../assets/img/landingIcons/icon4.svg';
import Icon5 from '../../assets/img/landingIcons/icon5.svg';
import Icon6 from '../../assets/img/landingIcons/icon6.svg';

export const SUCCESS_SEND_MSG = 'Data was successfully sent';
export const IMAGE_REMOVED = 'Image was successfully removed';
export const COPIED_SUCCESS = 'Copied successfully!';
export const COPIED_FAIL = 'Copied failed';
export const UNBLOCK_WINDOW_POPUP =
  'Window popups is blocked by browser. Please unblock it to proceed';
export const EMPTY = '-';
export const PROMT_LANDING_PAGE = [
  'We review the best activities & hotels for all budgets so you can plan your best trip',
  'The only app that can rank activities & hotel offers based on personal preferences and budget',
];
export const ACTIVITY_BOOK_BUTTON = 'BOOK ACTIVITY';

export const PREFIX_PROPERTY_BOOK_BUTTON = ' View on ';
export const PROPERTY_BOOK_BUTTON = 'Booking.com';

export const BOOK_BUTTON_TEXT =
  'This is an affiliate link, we may receive a commission from the vendor, but the price is the same for you';

export const PROPERTY_NOTES_TOOLTIP =
  'These are estimated offer prices based on our analysis of seasonal rates for this property. You can complete the reservation process with our partner Booking.com at no extra cost';

export const FEATURES_TEXT = [
  {
    label: 'Expert reviews',
    text:
      'Our analysts do the research \n' +
      '& write comprehensive \n' +
      'reviews. No AI trick',
    icon: Icon1,
  },
  {
    label: 'Extensive coverage',
    text:
      '3,000+ activity & hotel \n' + 'reviews in over 90 \n' + 'destinations',
    icon: Icon2,
  },
  {
    label: 'Personalized results',
    text:
      'Tell us your preferences, \n' +
      'we’ll evaluate options \n' +
      'accordingly',
    icon: Icon3,
  },
  {
    label: 'Rank hotel offers',
    text:
      'What type of room in which \n' +
      'hotel? With breakfast? \n' +
      'Refundable? Let us compare',
    icon: Icon4,
  },
  {
    label: 'Environmental & Social',
    text:
      'You care about it? So do we, \n' +
      'and those aspects feed in \n' +
      'the results',
    icon: Icon5,
  },
  {
    label: 'Bespoke guidebooks',
    text:
      'Itinerary + activity reviews \n' +
      'combined in a pdf. Everything \n' +
      'you need',
    icon: Icon6,
  },
];
