export const activityRatingCommentLabels = {
  interestAndQualityComment: 'Interest & Quality',
  experienceIntensityComment: 'Experience Intensity',
  locationAndAccessComment: 'Location & Access',
  comfortAndServiceComment: 'Comfort & Service',
  foodAndBeveragesComment: 'Food & Beverages',
  environmentalAndSocialComment: 'Environmental & Social',
};

export const reviewTypes = {
  ONSITE: 'Onsite',
  DESKTOP: 'Desktop',
};

export const includedItemsLabels = {
  FOOD: 'Food',
  DRINKS: 'Drinks (non-alc.)',
  ALCOHOLIC_DRINKS: 'Alcoholic drinks',
  TRANSPORT: 'Transport to/from',
  ACCOMMODATION: 'Accommodation',
};

export const durationKeys = [
  'avgTransportTimeInTimeFormat',
  'estVisitTimeInTimeFormat',
  // todo: implement activity bookings and uncomment
  // 'numberOfDaysField'
];
export const durationOfVisitLabels = {
  avgTransportTimeInTimeFormat: 'av. transport time:',
  estVisitTimeInTimeFormat: 'est. visit time:',
  // todo: implement activity bookings and uncomment
  // numberOfDaysField: 'Number of days:',
};

export const continuousLabels = {
  NO: 'No',
  YES: 'Yes',
  NOT_AVAILABLE: 'N/A',
};

export const regulatedOrSunlightOptions = [
  {
    label: 'Regulated',
    value: 'REGULATED',
  },
  {
    label: 'Sunlight',
    value: 'SUNLIGHT',
  },
];

export const yesOrNoOptions = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'No',
    value: 'NO',
  },
];

export const sunlightBasedLabels = {
  SUNRISE: 'Sunrise / early morning',
  MORNING: 'Morning',
  LUNCH_TIME: 'Lunch time',
  AFTERNOON: 'Afternoon',
  EVENING: 'Sunset / evening',
  NIGHT_BEFORE_MIDNIGHT: 'Night – bef. midnight',
  NIGHT_AFTER_MIDNIGHT: 'Night – aft. midnight',
};
export const sunlightStatusLabels = {
  NO: 'No',
  BEST: 'Best',
  OK: 'Ok',
};

export const regulatedHoursPeriods = {
  one: 1,
  two: 2,
};
export const regulatedPeriodIndexes = {
  first: 0,
  second: 1,
};

export const seasonDateKeys = [
  'startOfFirstSeason',
  'startOfSecondSeason',
];

export const free = 'FREE';
export const numberBased = 'NUMBER_BASED';
export const yesValue = 'YES';

export const ageBandLabels = [
  'Age band',
  'Age band',
  'Regular price / pers.',
];

export const ageBandGroupLabels = [
  'Age band',
  'Age band',
  'Regular price / pers.',
  'Group price / pers.',
];

export const ageBandLabel = {
  SENIOR: 'Senior',
  ADULT: 'Adult',
  TEEN_AND_YOUNG: 'Teen & Young',
  CHILD: 'Child',
  INFANT: 'Infant',
};

export const numberBasedDetailsFields = [
  'maxNumberOfTravellers',
  'minChildAge',
  'maxChildAge',
];
export const numberBasedDetailsLabels = {
  maxNumberOfTravellers: 'Max number of travellers:',
  minChildAge: 'Min. Child age:',
  maxChildAge: 'Max. child age:',
};
export const numberBasedLabels = [
  'Traveller #',
  'Adult',
  'Child',
];

export const familyPriceFields = [
  {
    label: 'Max total number:',
    key: 'maxTotalNumber',
  },
  {
    label: 'Max number of adults:',
    key: 'maxNumberOfAdults',
  },
  {
    label: 'Min.child age:',
    key: 'minChildAge',
  },
  {
    label: 'Max.child age:',
    key: 'maxChildAge',
  },
];
