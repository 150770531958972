/* eslint-disable func-names,max-len */
import qs from 'qs';
import flatten from 'flat';
import moment from 'moment';
import get from 'lodash.get';
import toastr from 'toastr';
import { KM_TO_METERS } from '../constans/values';
import http from '../services/http.service';
import { API } from '../constans/http';
import { UNBLOCK_WINDOW_POPUP } from '../constans/texts/texts';
import { BODY_BACKDROPED, BODY_PR_17 } from '../constans/css-classes';
import {
  free,
  numberBased,
  regulatedOrSunlightOptions,
  yesOrNoOptions,
  yesValue,
} from '../constans/activity-review';

export const OPINIONS_TYPE = {
  meta: 'META_REVIEW',
  conclusion: 'CONCLUSION',
};

export const OPINIONS_KEYS = {
  positive: 'POSITIVE',
  negative: 'NEGATIVE',
};

export const noop = () => {};

export const objectToHash = (source) => JSON.stringify(source);

export const getQueryParams = (search) =>
  qs.parse(search, { ignoreQueryPrefix: true });

export const getDuration = ({ checkout, checkin }) =>
  moment
    .duration(moment(checkout).diff(moment(checkin)))
    .asDays()
    .toFixed();

export const defaultParamsSerializer = (params) =>
  qs.stringify(params, { skipNulls: true });

export const isBrowser = () => typeof window !== 'undefined';

export const pvPipe = (index, pvPoints, perPage, page) =>
  `#${index + 1 + page * perPage} PV : ${pvPoints ? pvPoints.toFixed(2) : 0}`;

export const propertiesFoundPipe = (count) =>
  `${count} propert${count === 1 ? 'y' : 'ies'} found`;

export const moneyPipe = (value) => {
  if (!value) {
    return value;
  }

  const [int, ...float] = value
    .toString()
    .replace(/[^\d.]/g, '')
    .split('.');

  const floatPart = float.join('').replace(/\./g, '').slice(0, 2);

  return `${int}${float.length ? '.' : ''}${floatPart}`;
};

export const integerPipe = (value) => {
  if (!value) {
    return value;
  }

  const [int] = value.toString().replace(/[^\d]/g, '').split('.');

  if (!int) {
    return '';
  }

  return Number(int);
};

export const integerPipeWithLimits = ({ value, maxValue, minValue }) => {
  const integer = integerPipe(value);
  let val;

  if (!Number.isInteger(integer)) {
    return '';
  }

  val = Math.max(integer, minValue);
  val = Math.min(val, maxValue);

  return val;
};

export const removeEmptyObjectKeys = (obj) => {
  const mutableObject = { ...obj };
  Object.keys(mutableObject).forEach((key) => {
    if (!mutableObject[key] && mutableObject[key] !== undefined) {
      delete mutableObject[key];
    }
  });

  return mutableObject;
};

export const removeNulls = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

export const parseFormikIntValue = (value, setValueFunc) => {
  if (!value) {
    setValueFunc('');
    return;
  }
  const parsedValue = parseInt(value, 10);
  setValueFunc(parsedValue || '');
};

export const filterByUniqueKey = (obj, key) =>
  obj.filter(
    (value, index, self) =>
      self.findIndex((element) => element[key] === value[key]) === index
  );

export const replaceStringSpacesByPlus = (string) => string.replace(/\s/g, '+');

// prettier-ignore
export const parseSearchParams = (location) => qs
  .parse(location.search, { ignoreQueryPrefix: true });

export const createNotEmptyFieldsObject = (objectToTest) => {
  const parsedObject = {};

  Object.entries(objectToTest).forEach(([key, value]) => {
    if (value || value === 0) {
      parsedObject[key] = value;
    }
  });

  return parsedObject;
};

// eslint-disable-next-line max-len
export const filterArrayForEmptyValues = (arrayToFilter) =>
  arrayToFilter.map((obj) => createNotEmptyFieldsObject(obj));

export const filterObjForEmptyValues = (nonFilteredObj) => {
  const filteredObj = {};
  Object.keys(nonFilteredObj).forEach((objKey) => {
    filteredObj[objKey] = filterArrayForEmptyValues(nonFilteredObj[objKey]);
  });
  return filteredObj;
};

export const convertKmToMeters = (objectToConvert) => {
  const convertedObject = flatten(objectToConvert);

  Object.keys(convertedObject).forEach((path) => {
    if (path.includes('distance')) {
      convertedObject[path] *= KM_TO_METERS;
    }
  });
  return flatten.unflatten(convertedObject);
};

export const convertMetersToKm = (objectToConvert) => {
  const convertedObject = flatten(objectToConvert);

  Object.keys(convertedObject).forEach((path) => {
    if (path.includes('distance')) {
      convertedObject[path] /= KM_TO_METERS;
    }
  });
  return flatten.unflatten(convertedObject);
};

export const objectIsNotEmpty = (object) => Object.keys(object).length > 0;

export const objectHasSomeKeys = (keysArr, obj) =>
  keysArr.some((key) => obj[key]);

export const filterAnchorsNavArray = (
  predefinedObjectItems,
  baseArray,
  targetObj
) => {
  const resultObj = {};
  baseArray.forEach((navItem) => {
    if (objectHasSomeKeys(navItem.keys, targetObj)) {
      resultObj[navItem.id] = navItem;
    }
  });
  return {
    ...predefinedObjectItems,
    ...resultObj,
  };
};

export const transformRoomAmenities = (amenitiesObj) => {
  let filteredGeneralAmenities = [];
  let transformedCustomAmenities = [];

  if (amenitiesObj.general.length > 0) {
    filteredGeneralAmenities = amenitiesObj.general
      .filter((amenity) => amenity.check)
      .map((amenity) => amenity.title);
  }

  if (amenitiesObj.custom.length > 0) {
    transformedCustomAmenities = amenitiesObj.custom.map(
      (amenity) => amenity.title
    );
  }

  return { filteredGeneralAmenities, transformedCustomAmenities };
};

export const generateMapFromListByKey = (list, key) => {
  const map = {};
  list.forEach((data) => {
    map[get(data, key)] = data;
  });
  return map;
};

export const replaceKeywords = (text, keywordsMap) => {
  if (!text) return text;
  Object.keys(keywordsMap).forEach((keyword) => {
    // eslint-disable-next-line no-param-reassign
    text = text.replace(`:${keyword}`, keywordsMap[keyword]);
  });
  return text;
};

export const mapCapacities = (roomData) => {
  const capacitiesModel = {
    designedCapacityForAdults: { group: 'design', value: '', prefix: 'A' },
    designedCapacityForKids: { group: 'design', value: '', prefix: 'K' },
    maximumCapacityForAdults: { group: 'max', value: '', prefix: 'A' },
    maximumCapacityForKids: { group: 'max', value: '', prefix: 'K' },
    optimizedCapacityForAdults: { group: 'optimized', value: '', prefix: 'A' },
    optimizedCapacityForKids: { group: 'optimized', value: '', prefix: 'K' },
  };

  const groupCapacitiesObj = {
    design: {
      modalLabel: 'Design capacity',
      cardLabel: 'Design capacity:',
      values: [],
    },
    optimized: {},
    max: { modalLabel: 'Maximum capacity', cardLabel: 'Maximum:', values: [] },
  };

  const filteredCapacitiesGroupModel = {};

  Object.keys(capacitiesModel).forEach((key) => {
    if (roomData[key]) {
      capacitiesModel[key].value = roomData[key];
      groupCapacitiesObj[capacitiesModel[key].group]?.values?.push(
        capacitiesModel[key]
      );
    }
  });

  // Group capacities

  Object.keys(groupCapacitiesObj).forEach((key) => {
    if (groupCapacitiesObj[key].values?.length) {
      filteredCapacitiesGroupModel[key] = groupCapacitiesObj[key];
    }
  });

  return filteredCapacitiesGroupModel;
};

export const createRoomCapacityValidatorRule = (maxFiled, minField) =>
  function () {
    const maxCapAdults = this.parent.main[maxFiled] || 0;
    const optCapAdults = this.parent.main[minField] || 0;
    return parseInt(maxCapAdults, 10) >= parseInt(optCapAdults, 10);
  };

export const formatTime = (time) => time.split(':').splice(0, 2).join(':');

export const filterObjectEmptyProperties = (objectToTest) => {
  const parsedObject = {};
  Object.entries(objectToTest).forEach(([key, value]) => {
    if (value?.length === 0) {
      return;
    }

    if (value || value === 0) {
      parsedObject[key] = value;
    }
  });
  return parsedObject;
};

export const setPaginState = (
  data,
  pagination,
  page,
  setRoomsList,
  setPageNumber,
  setTotal,
  setNextOffsetPage
) => {
  const roomListData = data.map((room) => ({
    ...room,
    capacitiesObj: mapCapacities(room),
  }));
  setRoomsList(roomListData);
  setPageNumber(page);
  setTotal(pagination.totalCount);
  setNextOffsetPage(pagination.nextOffset);
};

export const parseOpinionsKeys = (baseArray, type) =>
  baseArray
    .filter((metaItem) => metaItem.keyType === type)
    .map(({ note, keyType }) => ({
      note,
      keyType,
    }));

export const getReviewPreviewData = async (propertyId) => {
  const { data: generalResp } = await http.get(
    `/properties/${propertyId}/review`
  );

  return {
    description: generalResp.description || '',
    metaReviewSummary: generalResp.metaReviewSummary || '',
    suitability: generalResp.suitability || '',
    conclusion: generalResp.conclusion || '',
    comfortAndServiceComment:
      generalResp?.rating?.comfortAndServiceComment || '',
    atmosphereAndDesignComment:
      generalResp?.rating?.atmosphereAndDesignComment || '',
    locationAndConvenienceComment:
      generalResp?.rating?.locationAndConvenienceComment || '',
    facilitiesAndActivitiesComment:
      generalResp?.rating?.facilitiesAndActivitiesComment || '',
    foodAndBeveragesComment: generalResp?.rating?.foodAndBeveragesComment || '',
    environmentalAndSocialComment:
      generalResp?.rating?.environmentalAndSocialComment || '',
    conclusionKeysArrPositive: generalResp?.reviewOpinions
      ? parseOpinionsKeys(generalResp.reviewOpinions, OPINIONS_KEYS.positive)
      : [],
    conclusionKeysArrNegative: generalResp?.reviewOpinions
      ? parseOpinionsKeys(generalResp.reviewOpinions, OPINIONS_KEYS.negative)
      : [],
    reviewProvider: generalResp.reviewProvider,
    aboutRating: generalResp.aboutRating,
    ratingType: generalResp.ratingType,
  };
};

export const kFormatter = (num) =>
  Math.abs(num) > 999
    ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
    : Math.sign(num) * Math.abs(num);

export const formatCurrency = (currencyCode, value, locale = 'en-HK') =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(value);

export const checkToDisableOfferStartDates = (current, offerId) => {
  if (!offerId) {
    return null;
  }

  return moment().add(0, 'days') >= current;
};

export const checkToDisableOfferEndDates = (current, offerId, startDate) => {
  if (!offerId || !startDate) {
    return null;
  }
  return moment(startDate).add(0, 'days') >= current;
};

export const setDisableStartDates = (current, offerId) => {
  if (!offerId) {
    return null;
  }
  return moment().endOf('day') >= current;
};

export const stripeConnectHandler = (toggleLoading) => {
  if (isBrowser) {
    toggleLoading();
    http
      .post(API.SUPPLIERS_STRIPE_CREATE)
      .then(({ data }) => {
        const redirectLink = window.open(data.url, '_self');
        toggleLoading();

        if (!redirectLink) {
          toastr.error(UNBLOCK_WINDOW_POPUP);
        }
      })
      .catch(() => {
        toggleLoading();
      });
  }
};

export const toggleBodyStylesForModal = () => {
  document.body.classList.toggle(BODY_BACKDROPED);
  document.body.classList.toggle(BODY_PR_17);
};

export const setFormikFieldMinValue = (
  setFieldValue,
  fieldName,
  minValue,
  value
) => {
  if (parseInt(value, 10) < parseInt(minValue, 10)) {
    setFieldValue(fieldName, minValue);
  }
};

export const offerFloatPriceCalcDifference = (newOfferPrice, oldOfferPrice) =>
  Math.abs(parseFloat(newOfferPrice) - parseFloat(oldOfferPrice)).toFixed(2);

export const setCurrentPaginationPageMultiple = (
  nextOffsetPage,
  total,
  pageNumber,
  limit,
  deleteAmount
) => {
  if (nextOffsetPage - total <= 0) {
    return { selected: pageNumber };
  }

  if (nextOffsetPage - total > 0) {
    return {
      selected:
        nextOffsetPage - limit - (total - deleteAmount) === 0
          ? pageNumber > 0
            ? pageNumber - 1
            : pageNumber
          : pageNumber,
    };
  }
  return { selected: pageNumber };
};

export const createInputsFromFields = (object) =>
  Object.entries(object).map(([name, label]) => ({ name, label }));

export const createRadioOptionsFromObject = (object) =>
  Object.entries(object).map(([value, label]) => ({ label, value }));

export const createCheckboxOptionsFromObject = (object) =>
  Object.entries(object).map(([value, label], index) => ({
    title: label,
    id: index,
    value,
  }));

export const objectToActiveKeysArray = (object) =>
  Object.entries(object).reduce((acc, [key, value]) => {
    if (value) {
      return [...acc, key];
    }

    return acc;
  }, []);

export const getDestinationName = (district, city, region, country) =>
  [district?.name, city?.name, region?.name, country?.name]
    .filter((value) => !!value)
    .join(', ');

export const getShortDestinationName = (country, region, city, district) => {
  if (district) {
    return district.name;
  }

  if (city) {
    return city.name;
  }
  if (region) {
    return region.name;
  }
  return country.name;
};

export const getShortDestinationNameAndId = (ids) => {
  if (ids.districtId) {
    return {
      id: ids.districtId,
      patch: 'districts',
    };
  }
  if (ids.cityId) {
    return {
      id: ids.cityId,
      patch: 'cities',
    };
  }
  if (ids.regionId) {
    return {
      id: ids.regionId,
      patch: 'regions',
    };
  }
  if (ids.countryId) {
    return {
      id: ids.countryId,
      patch: 'countries',
    };
  }
};

export const getSeasonalityStates = ({
  regulatedOrSunlight,
  splitRegulatedHours,
  seasonalityInOpening,
  numberOfDaysField,
}) => {
  const isRegulated =
    regulatedOrSunlight === regulatedOrSunlightOptions[0].value;
  const isSunlight =
    regulatedOrSunlight === regulatedOrSunlightOptions[1].value;
  const isWithSeasonality = seasonalityInOpening === yesOrNoOptions[0].value;
  const isWithSplitRegulatedHours =
    splitRegulatedHours === yesOrNoOptions[0].value;
  const isOneDayVisit = numberOfDaysField < 2;

  // SUNLIGHT
  // w/ seasonality
  const isSunlightSeasonal = !isRegulated && isWithSeasonality;

  // REGULAR
  // w/ seasonal
  const isRegulatedSeasonal = isRegulated && isWithSeasonality;
  // w/o seasonality.
  const isRegulatedNonSeasonal = isRegulated && !isWithSeasonality;

  // /w seasonality w/o split hours
  const isRegulatedSeasonalNonSplit =
    isRegulatedSeasonal && !isWithSplitRegulatedHours;
  // w/o seasonality, w/ split hours
  const isRegulatedNonSeasonalSplit =
    isRegulatedNonSeasonal && isWithSplitRegulatedHours;
  // w/o seasonality, w/o split hours
  const isRegulatedNonSplitNonSeasonal =
    isRegulatedNonSeasonal && !isWithSplitRegulatedHours;
  // w/ seasonality, w/ split hours
  const isRegulatedSeasonalSplit =
    isRegulatedSeasonal && isWithSplitRegulatedHours;

  return {
    isRegulatedSeasonal,
    isRegulatedSeasonalSplit,
    isRegulatedNonSplitNonSeasonal,
    isRegulatedNonSeasonalSplit,
    isRegulatedSeasonalNonSplit,
    isSunlightSeasonal,
    isOneDayVisit,
    isRegulated,
    isSunlight,
    isWithSeasonality,
    isWithSplitRegulatedHours,
  };
};

export const getPricingStates = ({
  teenAndYoungPrice,
  pricingStructure,
  payingOrFree,
  familyPrice,
  groupPrice,
}) => {
  const isFree = payingOrFree === free;
  const isNumberBased = pricingStructure === numberBased;
  const isGroup = groupPrice === yesValue;
  const isTeens = teenAndYoungPrice === yesValue;
  const isFamily = familyPrice === yesValue;

  return {
    isFree,
    isNumberBased,
    isGroup,
    isTeens,
    isFamily,
  };
};

export const filterObjectByKeys = (object, fieldNamesArray = []) =>
  Object.entries(object).reduce(
    (previousValue, [key, value]) =>
      fieldNamesArray.includes(key)
        ? { ...previousValue, [key]: value }
        : previousValue,
    {}
  );

/**
 * transforms time number value to hh:mm
 * @example
 * decimalToHourPipe(1.5); // returns 1h 30m
 * decimalToHourPipe(1); // returns 1h
 * decimalToHourPipe(0.5); // returns 30m
 * decimalToHourPipe('13.33333'); // returns 13h 20m
 * @param { number | string } decimal time as decimal
 * @returns { string } time as a `hh:mm` string
 */
export const decimalToHourPipe = (decimal) => {
  if (!decimal) {
    return '';
  }

  const hrs = parseInt(Number(decimal), 10);
  const mins = Math.round((Number(decimal) - hrs) * 60);

  const displayHrs = hrs ? `${hrs}h ` : '';
  const displayMins = mins ? `${mins}m` : '';

  return `${displayHrs}${displayMins}`;
};

export const timePipe = (time) => {
  const [hh, mm] = time.split(':');

  return `${Number(hh)}h ${Number(mm) > 0 ? `${mm}m` : ''}`;
};

/**
 * Returns filtered array of uniq values
 * @param {Array.<*>} array array to filter
 * @param {Function} cond test condition
 * @returns {Array.<*>} fitlered array
 */
export const uniqueBy = (array, cond) =>
  array.filter(
    (element, i) => array.findIndex((element2) => cond(element, element2)) === i
  );

export const getHoursFromTimeString = (time) =>
  moment(`1995-12-17T${time}`).hours();
export const getTimeFromTimeString = (time) =>
  moment(`1995-12-17T${time}`).format();

export const getDurationTime = (start, end) =>
  (
    moment
      .duration(moment(`1995-12-17T${end}`).diff(moment(`1995-12-17T${start}`)))
      .asMinutes() / 60
  ).toFixed(2);

export const chunk = (arr, chunkSize, cache = []) => {
  const tmp = [...arr];

  if (chunkSize <= 0) return cache;

  while (tmp.length) cache.push(tmp.splice(0, chunkSize));

  return cache;
};

export default chunk;

export const isDestinationAllValue = (destination) =>
  destination?.endsWith('-00');

export const isAllValuesFalse = (object) =>
  Object.values(object).every((value) => value === false);

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const isArraysEquals = (array1, array2) =>
  array1.length === array2.length &&
  array1.every((element, index) => element === array2[index]);

export const removeProps = (obj, propsToRemove) =>
  obj.reduce((acc, item) => {
    if (!propsToRemove.includes(item)) acc.push(item);
    return acc;
  }, []);
